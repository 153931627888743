<template>
  <b-card>
    {{ daformPlanta }}
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form @submit.prevent="handleSubmit(submit())">
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              :name="$t('admin.plans.planeName')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.plans.planeName')"
                label-for="planeName"
              >
                <b-form-input
                  v-model="formPlan.name"
                  class="form-control"
                  :state="validationContext.errors[0] == null ? null : false"
                />
                <b-form-invalid-feedback :state="false">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('admin.plans.headers.role')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.plans.headers.role')"
                label-for="roleType"
              >
                <b-form-select
                  id="input-6"
                  v-model="formPlan.type"
                  :options="options"
                  required
                  :state="validationContext.errors[0] == null ? null : false"
                ></b-form-select>
                <b-form-invalid-feedback :state="false">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.listLabs.opticHeaders.qtyMeasurements')"
              rules="required|integer|positive"
            >
              <b-form-group
                :label="$t('admin.labs.listLabs.opticHeaders.qtyMeasurements')"
                label-for="qtyMeasurements"
              >
                <b-form-input
                  v-model="formPlan.measurements"
                  :state="validationContext.errors[0] == null ? null : false"
                  class="form-control"
                />
                <b-form-invalid-feedback :state="false">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.listLabs.opticHeaders.qtySellers')"
              rules="required|integer|positive"
            >
              <b-form-group
                :label="$t('admin.labs.listLabs.opticHeaders.qtySellers')"
                label-for="qtySellers"
              >
                <b-form-input
                  v-model="formPlan.sellers"
                  class="form-control"
                  :state="validationContext.errors[0] == null ? null : false"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-2 pr-1" align-h="end" v-if="!isFromRequestAccessForm">
          <b-button
            v-if="!profile"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'plans' }"
          >
            {{ $t('admin.labs.form.cancel') }}
          </b-button>
          <b-button
            type="submit"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="loading || invalid"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-spinner v-if="loading" small></b-spinner>

            <span v-else>{{ $t('admin.labs.form.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import planStoreModule from '@/views/admin/plans/planStoreModule'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, email, positive } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      formPlan: {},
      options: [
        { text: 'Select Plan Type', value: null },
        'Laboratory',
        'Optic',
        'Software Partner',
      ],
      show: true,
    }
  },

  setup() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }
    onUnmounted(
      () =>
        store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME),
    )
  },
  methods: {
    submit() {
      console.log('submit')
      this.formPlan.createdAt = new Date().toISOString()
      store.dispatch('plan/createPlan', this.formPlan)
      this.$router.push({ name: 'plans' })
    },
  },
}
</script>
